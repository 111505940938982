import React from 'react'
import styles from './informationPanel.module.scss'
import classNames from 'classnames'
import { Markdown } from 'components/sparkles'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export interface InformationPanelProps {
  children?: React.ReactNode
  className?: string
  hasBackground?: boolean
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  faIcon?: IconDefinition
  iconColor?: 'danger'
  isTitleHighlighted?: boolean
  panelOutlineColor?: 'danger'
  text: string
  textAlign?: 'left' | 'center'
  textColor?: 'danger'
  title?: string
}

const InformationPanel: React.FC<InformationPanelProps> = ({
  children,
  className,
  hasBackground,
  isTitleHighlighted,
  icon,
  faIcon,
  iconColor,
  panelOutlineColor,
  text,
  title,
  textAlign,
  textColor,
}) => {
  const textAlignClass =
    textAlign === 'left' ? styles.textLeft : styles.textCenter
  const textColorClass = textColor && styles[`textColor-${textColor}`]
  const textStyle = classNames(styles.text, textAlignClass, textColorClass)
  const panelAlignClass =
    textAlign === 'left' ? styles.panelLeft : styles.panelCenter
  const panelBackgroundClass = hasBackground && styles.background
  const panelOutlineColorClass = panelOutlineColor
    ? styles[`panelOutlineColor-${panelOutlineColor}`]
    : styles.panelOutlineColor
  return (
    <div
      className={classNames(
        className,
        panelAlignClass,
        panelBackgroundClass,
        panelOutlineColorClass,
        styles.panel,
      )}
    >
      {icon && (
        <div className={iconColor && styles[`iconColor-${iconColor}`]}>
          {React.createElement(icon)}
        </div>
      )}
      {faIcon && (
        <FontAwesomeIcon
          className={iconColor && styles[`iconColor-${iconColor}`]}
          icon={faIcon}
        />
      )}
      {title && (
        <h2
          className={
            isTitleHighlighted ? styles.titleHighlighted : styles.title
          }
        >
          {title}
        </h2>
      )}
      <div>
        <Markdown className={textStyle}>{text}</Markdown>
        {children}
      </div>
    </div>
  )
}

export default InformationPanel
