/* eslint-disable import/prefer-default-export */

const prefix = '@@ce-api-client'

export const FETCH = `${prefix}/FETCH`
export const FETCH_ERROR = `${prefix}/FETCH_ERROR`
export const FETCH_RECEIVE = `${prefix}/FETCH_RECEIVE`
export const FETCH_REQUEST = `${prefix}/FETCH_REQUEST`

// Authentication
export const SIGNED_IN = `${prefix}/SIGNED_IN`
export const SIGNING_IN = `${prefix}/SIGNING_IN`
export const SIGNING_IN_ERROR = `${prefix}/SIGNING_IN_ERROR`
export const SIGN_OUT = `${prefix}/SIGN_OUT`

// Ballots
export const CLEAR_BALLOT = `${prefix}/CLEAR_BALLOT`
export const RECEIVE_BALLOT = `${prefix}/RECEIVE_BALLOT`
export const REQUEST_BALLOT = `${prefix}/REQUEST_BALLOT`
export const SAVED_BALLOT = `${prefix}/SAVED_BALLOT`
export const SAVING_BALLOT = `${prefix}/SAVING_BALLOT`
export const SAVE_BALLOT_PARAMS = `${prefix}/SAVE_BALLOT_PARAMS`

// Ballot user
export const RECEIVE_BALLOT_USER = `${prefix}/RECEIVE_BALLOT_USER`
export const REQUEST_BALLOT_USER = `${prefix}/REQUEST_BALLOT_USER`
export const SAVED_BALLOT_USER = `${prefix}/SAVED_BALLOT_USER`
export const SAVING_BALLOT_USER = `${prefix}/SAVING_BALLOT_USER`

// Ballot user password reset
export const REQUESTING_PASSWORD_RESET = `${prefix}/REQUESTING_PASSWORD_RESET`
export const REQUESTED_PASSWORD_RESET = `${prefix}/REQUESTED_PASSWORD_RESET`
export const RESET_PASSWORD = `${prefix}/RESET_PASSWORD`

// Candidate
export const RECEIVE_CANDIDATE = `${prefix}/RECEIVE_CANDIDATE`
export const REQUEST_CANDIDATE = `${prefix}/REQUEST_CANDIDATE`
export const RECEIVE_CANDIDATES = `${prefix}/RECEIVE_CANDIDATES`
export const REQUEST_CANDIDATES = `${prefix}/REQUEST_CANDIDATES`
export const RESET_CANDIDATES = `${prefix}/RESET_CANDIDATES`

// States
export const RECEIVE_STATE = `${prefix}/RECEIVE_STATE`
export const REQUEST_STATE = `${prefix}/REQUEST_STATE`

// County for a ballot
export const RECEIVE_BALLOT_COUNTY = `${prefix}/RECEIVE_BALLOT_COUNTY`
export const REQUEST_BALLOT_COUNTY = `${prefix}/REQUEST_BALLOT_COUNTY`

// Places for a ballot
export const RECEIVE_BALLOT_PLACES = `${prefix}/RECEIVE_BALLOT_PLACES`
export const REQUEST_BALLOT_PLACES = `${prefix}/REQUEST_BALLOT_PLACES`

// Directory
export const RECEIVE_POSITIONS_BY_STATE = `${prefix}/RECEIVE_POSITIONS_BY_STATE`
export const REQUEST_POSITIONS_BY_STATE = `${prefix}/REQUEST_POSITIONS_BY_STATE`

// Districts
export const RECEIVE_DISTRICTS = `${prefix}/RECEIVE_DISTRICTS`
export const REQUEST_DISTRICTS = `${prefix}/REQUEST_DISTRICTS`

// Election
export const RECEIVE_ELECTION = `${prefix}/RECEIVE_ELECTION`
export const REQUEST_ELECTION = `${prefix}/REQUEST_ELECTION`
export const CLEAR_ELECTION = `${prefix}/CLEAR_ELECTION`

// Election form
export const RECEIVE_ELECTION_FORM = `${prefix}/RECEIVE_ELECTION_FORM`
export const REQUEST_ELECTION_FORM = `${prefix}/REQUEST_ELECTION_FORM`

// Parties in election
export const RECEIVE_ELECTION_PARTIES = `${prefix}/RECEIVE_ELECTION_PARTIES`
export const REQUEST_ELECTION_PARTIES = `${prefix}/REQUEST_ELECTION_PARTIES`

// Elections (multiple for dashboard)
export const RECEIVE_ELECTIONS = `${prefix}/RECEIVE_ELECTIONS`

// Email
export const SENDING_EMAIL = `${prefix}/SENDING_EMAIL`
export const SENT_EMAIL = `${prefix}/SENT_EMAIL`
export const EMAIL_ERROR = `${prefix}/EMAIL_ERROR`
export const CLEAR_EMAIL = `${prefix}/CLEAR_EMAIL`

// Endorsed Candidates
export const RECEIVE_ENDORSED_CANDIDATES = `${prefix}/RECEIVE_ENDORSED_CANDIDATES`
export const REQUEST_ENDORSED_CANDIDATES = `${prefix}/REQUEST_ENDORSED_CANDIDATES`

// id upload
export const RECEIVE_ID_UPLOAD_PARAMS = `${prefix}/RECEIVE_ID_UPLOAD_PARAMS`
export const REQUEST_ID_UPLOAD_PARAMS = `${prefix}/REQUEST_ID_UPLOAD_PARAMS`

// Leads
export const SAVED_LEAD = `${prefix}/SAVED_LEAD`

// Map
export const REQUEST_NORMALIZED_POSITION = `${prefix}/REQUEST_NORMALIZED_POSITION`
export const RECEIVE_NORMALIZED_POSITION = `${prefix}/RECEIVE_NORMALIZED_POSITION`
export const NORMALIZED_POSITION_ERROR = `${prefix}/NORMALIZED_POSITION_ERROR`

// Measures
export const RECEIVE_MEASURES = `${prefix}/RECEIVE_MEASURES`
export const REQUEST_MEASURES = `${prefix}/REQUEST_MEASURES`

// Office Holders
export const RECEIVE_OFFICE_HOLDERS = `${prefix}/RECEIVE_OFFICE_HOLDERS`
export const REQUEST_OFFICE_HOLDERS = `${prefix}/REQUEST_OFFICE_HOLDERS`
export const RECEIVE_OFFICE_HOLDER = `${prefix}/RECEIVE_OFFICE_HOLDER`
export const REQUEST_OFFICE_HOLDER = `${prefix}/REQUEST_OFFICE_HOLDER`
export const ERROR_OFFICE_HOLDERS = `${prefix}/ERROR_OFFICE_HOLDERS`

// Polling Days
export const RECEIVE_POLLING_DAYS = `${prefix}/RECEIVE_POLLING_DAYS`
export const REQUEST_POLLING_DAYS = `${prefix}/REQUEST_POLLING_DAYS`

// Polling Places
export const RECEIVE_POLLING_PLACES = `${prefix}/RECEIVE_POLLING_PLACES`
export const REQUEST_POLLING_PLACES = `${prefix}/REQUEST_POLLING_PLACES`

// Polling Places by Proximity
export const RECEIVE_POLLING_PLACES_PROXIMITY = `${prefix}/RECEIVE_POLLING_PLACES_PROXIMITY`
export const REQUEST_POLLING_PLACES_PROXIMITY = `${prefix}/REQUEST_POLLING_PLACES_PROXIMITY`

// Position (singular)
export const RECEIVE_POSITION = `${prefix}/RECEIVE_POSITION`
export const REQUEST_POSITION = `${prefix}/REQUEST_POSITION`

// Ballot Positions
export const RECEIVE_BALLOT_POSITIONS = `${prefix}/RECEIVE_BALLOT_POSITIONS`
export const REQUEST_BALLOT_POSITIONS = `${prefix}/REQUEST_BALLOT_POSITIONS`

// Positions
export const RECEIVE_POSITIONS = `${prefix}/RECEIVE_POSITIONS`
export const REQUEST_POSITIONS = `${prefix}/REQUEST_POSITIONS`

// Positions by county
export const RECEIVE_POSITIONS_BY_COUNTY = `${prefix}/RECEIVE_POSITIONS_BY_COUNTY`
export const REQUEST_POSITIONS_BY_COUNTY = `${prefix}/REQUEST_POSITIONS_BY_COUNTY`

// Position's Candidates
export const INVALIDATE_POSITION_CANDIDATES = `${prefix}/INVALIDATE_POSITION_CANDIDATES`
export const RECEIVE_POSITION_CANDIDATES = `${prefix}/RECEIVE_POSITION_CANDIDATES`
export const REQUEST_POSITION_CANDIDATES = `${prefix}/REQUEST_POSITION_CANDIDATES`
export const RESET_ALL_POSITION_CANDIDATES = `${prefix}/RESET_ALL_POSITION_CANDIDATES`

// Registration
export const REQUEST_PA_API_SETUP = `${prefix}/START_FETCH_PA_API_SETUP`
export const RECEIVE_PA_API_SETUP = `${prefix}/RECEIVE_PA_API_SETUP`
export const ERROR_PA_API_SETUP = `${prefix}/ERROR_PA_API_SETUP`
export const REQUEST_PA_API_FORM_SUBMIT = `${prefix}/REQUEST_PA_API_FORM_SUBMIT`
export const RECEIVE_PA_API_FORM_SUBMIT = `${prefix}/RECEIVE_PA_API_FORM_SUBMIT`
export const ERROR_PA_API_FORM_SUBMIT = `${prefix}/ERROR_PA_API_FORM_SUBMIT`
export const REQUEST_PA_API_POLLING = `${prefix}/REQUEST_PA_API_POLLING`
export const RECEIVE_PA_API_POLLING = `${prefix}/RECEIVE_PA_API_POLLING`
export const ERROR_PA_API_POLLING = `${prefix}/ERROR_PA_API_POLLING`
export const POLL_PA_API = `${prefix}/POLL_PA_API`
export const SAVE_PA_API_FORM_LOCALLY = `${prefix}/SAVE_PA_API_FORM_LOCALLY`

// Registration - PA Municipalities
export const CLEAR_PA_API_MUNICIPALITIES = `${prefix}/CLEAR_PA_API_MUNICIPALITIES`
export const RECEIVE_PA_API_MUNICIPALITIES = `${prefix}/RECEIVE_PA_API_MUNICIPALITIES`
export const REQUEST_PA_API_MUNICIPALITIES = `${prefix}/REQUEST_PA_API_MUNICIPALITIES`

// Selections
export const DESTROYED_SELECTION = `${prefix}/DESTROYED_SELECTION`
export const DESTROYING_SELECTION = `${prefix}/DESTROYING_SELECTION`
export const RECEIVE_SELECTIONS = `${prefix}/RECEIVE_SELECTIONS`
export const REQUEST_SELECTIONS = `${prefix}/REQUEST_SELECTIONS`
export const SAVED_SELECTION = `${prefix}/SAVED_SELECTION`
export const SAVING_SELECTION = `${prefix}/SAVING_SELECTION`

// Shared ballots
export const RECEIVE_SHARED_BALLOT = `${prefix}/RECEIVE_SHARED_BALLOT`
export const REQUEST_SHARED_BALLOT = `${prefix}/REQUEST_SHARED_BALLOT`

// Subscriptions
export const RECEIVE_CREATE_SUBSCRIPTION = `${prefix}/RECEIVE_CREATE_SUBSCRIPTION`
export const REQUEST_CREATE_SUBSCRIPTION = `${prefix}/REQUEST_CREATE_SUBSCRIPTION`
export const COMPLETED_SUBSCRIPTION_CALL = `${prefix}/COMPLETED_SUBSCRIPTION_CALL`

// Tenant elections (not ballot scoped, for homepage)
export const RECEIVE_TENANT_ELECTIONS = `${prefix}/RECEIVE_TENANT_ELECTIONS`
export const REQUEST_TENANT_ELECTIONS = `${prefix}/REQUEST_TENANT_ELECTIONS`

// Time travel
export const SET_IS_TIME_TRAVELING = `${prefix}/SET_IS_TIME_TRAVELING`

// Voting Plans
export const REQUEST_VOTING_PLAN = `${prefix}/REQUEST_VOTING_PLAN`
export const RECEIVE_VOTING_PLAN = `${prefix}/RECEIVE_VOTING_PLAN`
export const SAVED_VOTING_PLAN = `${prefix}/SAVED_VOTING_PLAN`
export const SAVED_ABSENTEE_BALLOT_REQUEST_FORM = `${prefix}/SAVED_ABSENTEE_BALLOT_REQUEST_FORM`
export const SAVE_FAILED_ABSENTEE_BALLOT_REQUEST_FORM = `${prefix}/SAVE_FAILED_ABSENTEE_BALLOT_REQUEST_FORM`
export const SAVING_ABSENTEE_BALLOT_REQUEST_FORM = `${prefix}/SAVING_ABSENTEE_BALLOT_REQUEST_FORM`
export const SAVED_VOTER_REGISTRATION_FORM = `${prefix}/SAVED_VOTER_REGISTRATION_FORM`
export const SAVE_FAILED_VOTER_REGISTRATION_FORM = `${prefix}/SAVE_FAILED_VOTER_REGISTRATION_FORM`
export const SAVING_VOTER_REGISTRATION_FORM = `${prefix}/SAVING_VOTER_REGISTRATION_FORM`

// Check registration
export const RECEIVE_CHECK_REGISTRATION = `${prefix}/RECEIVE_CHECK_REGISTRATION`
export const REQUEST_CHECK_REGISTRATION = `${prefix}/REQUEST_CHECK_REGISTRATION`

export const SELECT_CANDIDATE = 'SELECT_CANDIDATE'
export type SELECT_CANDIDATE = typeof SELECT_CANDIDATE

export const SELECT_ISSUE = 'SELECT_ISSUE'
export type SELECT_ISSUE = typeof SELECT_ISSUE

export const SELECT_MEASURE = 'SELECT_MEASURE'
export type SELECT_MEASURE = typeof SELECT_MEASURE

export const SELECT_POSITION = 'SELECT_POSITION'
export type SELECT_POSITION = typeof SELECT_POSITION

export const TOGGLE_VOTER_GUIDE_ALERT = 'TOGGLE_VOTER_GUIDE_ALERT'
export const TOGGLE_GLOBAL_ALERT = 'TOGGLE_GLOBAL_ALERT'
export const TOGGLE_LEAD_BAR = 'TOGGLE_LEAD_BAR'
export const TOGGLE_SHAREABLE_BALLOT_BANNER = 'TOGGLE_SHAREABLE_BALLOT_BANNER'
export const TOGGLE_SIGN_UP_PROMPT = 'TOGGLE_SIGN_UP_PROMPT'
export const TOGGLE_NO_ELECTION_AVAILABLE = 'TOGGLE_NO_ELECTION_NOTIFICATION'

// Ballot search
export const PING_BALLOT = 'PING_BALLOT'
export const PING_BALLOT_STOP = 'PING_BALLOT_STOP'

// Candidate Comments
export const SENT_CANDIDATE_COMMENT = 'SENT_CANDIDATE_COMMENT'

// MAPTV Flow
export const BEGIN_IN_PERSON_FLOW = 'BEGIN_IN_PERSON_FLOW'
export const SELECT_FLOW = 'SELECT_FLOW'
export const SELECT_MAIL_FLOW = 'SELECT_MAIL_FLOW'
export const SET_ALL_MAPTV_OPTIONS = 'SET_ALL_MAPTV_OPTIONS'
export const SELECT_IF_BALLOT_NEEDED = 'SELECT_IF_BALLOT_NEEDED'
export const SAVE_ABSENTEE_BALLOT_FORM = 'SAVE_ABSENTEE_BALLOT_FORM'
export const SAVE_ABSENTEE_BALLOT_REMINDER = 'SAVE_ABSENTEE_BALLOT_REMINDER'

// Request Ballot Flow
export const BEGIN_REQUEST_FLOW = 'BEGIN_REQUEST_FLOW'
export const ACQUIRED_FORM = 'ACQUIRED_FORM'
export const DISMISS_AUTOMATIC_VBM_ALERT = 'DISMISS_AUTOMATIC_VBM_ALERT'
export const REQUEST_IN_PERSON_REMINDERS = 'REQUEST_IN_PERSON_REMINDERS'
export const USE_FILLED_FORM = 'USE_FILLED_FORM'
export const SET_ATTEMPTING_FORM_SUBMIT = 'SET_ATTEMPTING_FORM_SUBMIT'
export const BEGIN_RETURN_FLOW = 'BEGIN_RETURN_FLOW'

// Check Registration
export const REQUESTING_BALLOT = 'REQUESTING_BALLOT'
export const SELECT_IF_REGISTERED = 'SELECT_IF_REGISTERED'
export const SELECT_REGISTRATION_FAILED_OPTION =
  'SELECT_REGISTRATION_FAILED_OPTION'
export const SET_VOTER_REGISTRATION_METHOD = 'SET_VOTER_REGISTRATION_METHOD'

// Request Ballot Printing Flow
export const TOGGLE_PRINT_BALLOT_REQUEST_STEP =
  'TOGGLE_PRINT_BALLOT_REQUEST_STEP'
export const PRINT_BALLOT_REQUEST_SUBMITTED = 'PRINT_BALLOT_REQUEST_SUBMITTED'
export const ID_UPLOAD_REQUIRED = 'ID_UPLOAD_REQUIRED'
export const REMOVE_UPLOADED_ID = 'REMOVE_UPLOADED_ID'

// Notifications
export const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION'

// Polling Days
export const SELECT_POLLING_DAY = 'SELECT_POLLING_DAY'

// Polling Places
export const SELECT_POLLING_PLACE = 'SELECT_POLLING_PLACE'

// Polling Time
export const SELECT_DATE_TIME = 'SELECT_DATE_TIME'

// Reminders
export const ADD_REMINDER_CHANNEL = 'ADD_REMINDER_CHANNEL'

// Step mgmt
export const PUSH_STEPS = 'PUSH_STEPS'
export const POP_STEP = 'POP_STEP'
export const PUSH_STEP = 'PUSH_STEP'

// Super Slate Card
export const FILTER_TAG = 'FILTER_TAG'

// Super Slate Card
export const APPLY_FILTERS = 'APPLY_FILTERS'
export type APPLY_FILTERS = typeof APPLY_FILTERS

export const CLEAR_FILTER = 'CLEAR_FILTER'
export type CLEAR_FILTER = typeof CLEAR_FILTER

export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export type CLEAR_FILTERS = typeof CLEAR_FILTERS

// Election center
export const SELECT_ELECTION_CENTER_SECTION = 'SELECT_ELECTION_CENTER_SECTION'
export const COMPLETE_SECTION = 'COMPLETE_SECTION'

export const SET_SHOW_ADDRESS_MODAL = 'SET_SHOW_ADDRESS_MODAL'

export const TOGGLE_AUTH_MODAL_OPEN = 'TOGGLE_AUTH_MODAL_OPEN'

export const REQUEST_SEARCH_DATA = 'REQUEST_SEARCH_DATA'
export const RECEIVE_SEARCH_DATA = 'RECEIVE_SEARCH_DATA'
export const SET_SEARCH_INITIATED = 'SET_SEARCH_INITATED'

// Recruitment engine
export const SET_RECRUITMENT_VALUES = 'SET_RECRUITMENT_VALUES'
