import React from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactModal from 'react-modal'
import { Button } from 'components/sparkles'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import styles from './modal.module.scss'
import { useTranslation } from 'react-i18next'

export interface ModalProps {
  appElement?: any
  children?: React.ReactNode
  className?: string
  bodyClassName?: string
  isOpen: boolean
  onClose?(event: React.MouseEvent | React.KeyboardEvent): void
  modalTitle?: string
  ariaLabelledBy?: string
  ariaDescribedBy?: string
}

const modalCloseTimeoutMS = 300

const Modal: React.FC<ModalProps> = ({
  appElement,
  children,
  className,
  bodyClassName,
  isOpen,
  onClose,
  modalTitle,
  ariaLabelledBy,
  ariaDescribedBy,
}) => {
  const { t } = useTranslation()
  return (
    <ReactModal
      appElement={appElement}
      className={{
        base: classNames(styles.Modal, className),
        afterOpen: styles['Modal--after-open'],
        beforeClose: styles['Modal--before-close'],
      }}
      closeTimeoutMS={modalCloseTimeoutMS}
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName={{
        base: styles.Modal__Overlay,
        afterOpen: styles['Modal__Overlay--after-open'],
        beforeClose: styles['Modal__Overlay--before-close'],
      }}
      contentLabel={modalTitle}
      aria={{
        labelledby: ariaLabelledBy,
        describedby: ariaDescribedBy,
      }}
    >
      <div data-testid="modal">
        {onClose && (
          <header className={styles.Modal__Header}>
            <Button
              className={styles.Modal__BtnClose}
              onClick={onClose}
              aria-label={t('sparkles_main:Modal.closeBtn')}
              data-testid="modal-close-button"
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </header>
        )}
        <section
          className={classNames(styles.Modal__Body, bodyClassName)}
          data-testid="modal-body"
        >
          {children}
        </section>
      </div>
    </ReactModal>
  )
}

export default Modal
