import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Markdown } from 'components/sparkles'
import { Ballot, Election } from 'types'
import ApiDate from 'utils/ApiDate'
import AppBoilerplate from 'components/templates/AppBoilerplate/AppBoilerplate'
import AddressModalController from 'components/AddressModalController/AddressModalController'
import noElectionSceneContainer from './noElectionSceneContainer'
import styles from './NoElectionScene.module.scss'

interface Props {
  ballot: Ballot
  elections: Election[]
  onUpdateAddress(): void
}

const NoElectionScene = ({ ballot, elections, onUpdateAddress }: Props) => {
  const { t, i18n } = useTranslation()

  return (
    <AppBoilerplate>
      <main role="main" className={styles.body}>
        <div className={styles.container}>
          <div className={styles.address}>
            {ballot?.address}
            <AddressModalController
              shouldRedirect={true}
              onUpdateAddress={onUpdateAddress}
              needsBallot={false}
              modalTitle={t('NoElectionScene.editAddress')}
            >
              {({ onClick }) => (
                <Button
                  className={styles.button}
                  onClick={onClick}
                  size="sm"
                  variant="link"
                >
                  {t('NoElectionScene.editAddress')}
                </Button>
              )}
            </AddressModalController>
          </div>

          {elections.length > 0 && <h1>{t('NoElectionScene.nextElection')}</h1>}
          {elections.map((election: Election, i: number) => {
            if (i >= 2) return null
            return (
              <>
                {election &&
                  new ApiDate(election.election_day).toLocaleDateString(
                    i18n.language,
                    {
                      month: 'short',
                      day: 'numeric',
                      year: 'numeric',
                    },
                  )}
              </>
            )
          })}

          <Markdown source={t('NoElectionScene.noelectionMessageMarkdown')} />
        </div>
      </main>
    </AppBoilerplate>
  )
}

export default noElectionSceneContainer(NoElectionScene)
